:root {
  --max-width: 1170px;
  --default-padding: 0 15px;
  --default-margin: 0 auto;
  --border-radius: 10px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --backgroundColor: #fff;
  --fontColor: #000;

  --default-purple: #5c5ccb;

  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #5758d91a;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--default-purple);
  border-radius: 10px;
}

html,
body {
  max-width: 100vw;
}

body {
  font-family: var(--generalFont);
  background: var(--backgroundColor);
  color: var(--fontColor);
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingFont);
}

button,
input,
textarea,
label,
select {
  font-family: inherit;
  color: inherit;
}
button,
input[type="button"] {
  cursor: pointer;
  border: 0;
  background: 0;
}

img {
  width: 100%;
  height: auto;
  max-width: fit-content;
}

ul,
li {
  list-style-type: none;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
a.linkHover:hover,
a.current {
  color: var(--default-purple);
}

pre {
  margin: 15px 0;
  padding: 15px;
  border: 1px solid var(--default-purple);
  border-radius: 10px;
  display: inline-block;
  overflow-x: auto;
  width: 100%;
  box-shadow: 4px 4px var(--default-purple);
  background: #f9f9f9;
}

.container {
  max-width: var(--max-width);
  width: 100%;
  padding: var(--default-padding);
  margin: var(--default-margin);
}
main.container {
  margin: 3rem auto;
}

.paginator {
  text-align: center;
  margin-top: 6rem !important;
}

.srOnly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.not-found h1 {
  font-size: 2.6rem;
}
.not-found h2 {
  margin-top: 2.5rem;
  font-size: 2rem;
}
.not-found .back-to-home {
  font-size: 1.6rem;
  margin-top: 1.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.adsTerra {
  display: block;
  text-align: center;
  line-height: 0;
  margin: 3rem 0;
}

@media (max-width: 1024px) {
  pre {
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    height: 5px;
  }
}
